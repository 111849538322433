import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ArrowRight, CheckCircle, Close, Politics } from "assets/svgs";
import { Button } from "components/Common/Button";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { dollarFormatter } from "utils/helpers";
import { View } from "react-native-web";


export default function EmployeeGiftSuccessModal({ isOpen, onClose, giftTotal, giftAmount, showSuccessModal }) {

	function closeModal() {
		onClose();
	}

	return (
		<Modal
			open={isOpen}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			onClose={closeModal}
		>



			<Box
				width={500}
				pt={32}
				pb={48}
				bgcolor={COLORS.primary.white}
				display="flex"
				alignItems="center"
				flexDirection="column"
				borderRadius={3}
				position="relative"
			>
				<IconButton
					sx={{ position: "absolute", top: 16, right: 20 }}
					onClick={closeModal}
				>
					<Close fill={COLORS.primary.gray} />
				</IconButton>
				<PrimaryText
					fontSize={24}
					fontWeight={900}
				>
					Employee Gift
				</PrimaryText>

				<PrimaryText
					fontSize={24}
					fontWeight={900}
					style={{ marginVertical: 16 }}
				>
					{showSuccessModal?.title}
				</PrimaryText>
				<PrimaryText
					fontSize={16}
					fontWeight={400}
					style={{ marginBottom: 16, textAlign: "center", paddingHorizontal: 12 }}
				>
					{showSuccessModal?.description}
				</PrimaryText>

				<View
					style={{
						flexDirection: 'row',
						alignItems: 'flex-start',
					}}>
					<PrimaryText
						fontSize={32}
						fontWeight={900}
						style={{ marginTop: 8 }}
					>
						$
					</PrimaryText>
					<PrimaryText
						fontSize={60}
						fontWeight={900}
					>
						{giftTotal}
					</PrimaryText>
				</View>

				<PrimaryText
					fontSize={20}
					fontWeight={400}
					color={COLORS.primary.neutral_500}
				>
					({dollarFormatter.format(giftAmount)} per employee)
				</PrimaryText>



				<PrimaryText fontSize={24} fontWeight={400} style={{ marginTop: 16, textAlign: 'center', paddingHorizontal: 24 }}>
					sent to employees
				</PrimaryText>
				<Button
					title="Finish"
					style={{
						backgroundColor: COLORS.primary.lightBlue,
						width: undefined,
						paddingVertical: 12,
						paddingHorizontal: 24,
						borderRadius: 100,
						marginTop: 24,
						text: {
							fontSize: 16,
							fontWeight: 900,
							color: COLORS.primary.white
						},
						alignSelf: 'center'
						// opacity: donateDisabled ? 0.5 : 1,
					}}
					onPress={() => {
						closeModal();
					}}
				/>


			</Box>

		</Modal>
	);
}


