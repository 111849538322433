import { Box, FormControl, IconButton, Modal, Radio } from "@mui/material";
import { Close } from "assets/svgs";
import PaymentMethodPickerCard from "components/Cards/PaymentMethodPickerCard";
import { Button } from "components/Common/Button";
import { PrimaryText } from "components/Common/PrimaryText";
import PaymentMethodPicker from "components/Inputs/PaymentMethodPicker";
import { COLORS } from "constants/Colors";
import React, { useEffect, useState } from "react";
import { LuChevronRight, LuCoins } from "react-icons/lu";
import { ActivityIndicator, StyleSheet, TextInput } from "react-native-web";
import { dollarFormatter } from "utils/helpers";

const RAYZE_AMOUNT = 10_801;


export default function EmployeeGiftModal(props) {
	const {
		isOpen,
		onClose,
		onNext,
		rayzeAccountBalance,
		giftAmount,
		setGiftAmount,
		giftFrequency,
		setGiftFrequency,
		giftTotal,
		setGiftTotal,
		numberOfEmployees,
		allEmployees,
		setAllEmployees,
		paymentMethods = [],
		selectedPaymentMethod,
		setSelectedPaymentMethod,
	} = props;

	const EMPLOYEE_SECTION = [
		{ title: "All Employees", caption: `(${numberOfEmployees} employees)` },
		{
			title: "Individual Employees"
		}
	];

	const [employeeSection, setEmployeeSection] = useState(
		EMPLOYEE_SECTION[0].title
	);
	const [showPaymentMethods, setShowPaymentMethods] = useState(false);
	const [loading, setLoading] = useState(false);


	const handleDollarChange = (e) => {
		let value = e.target.value;
		value = !value ? 0 : parseFloat(value);
		if (!isNaN(value)) setGiftAmount(value);
	};

	useEffect(() => {
		setGiftTotal(giftAmount * numberOfEmployees);
	}, [giftAmount]);

	useEffect(() => {
		if (employeeSection === EMPLOYEE_SECTION[0].title) {
			setAllEmployees(true);
			setGiftTotal(giftAmount * numberOfEmployees);
		} else {
			setAllEmployees(false);
		}
	}, [employeeSection]);


	const INSUFFICIENT = !selectedPaymentMethod &&
		employeeSection === EMPLOYEE_SECTION[0].title &&
		giftTotal > rayzeAccountBalance

	return (
		<Modal
			open={isOpen}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			onClose={onClose}
		>
			<Box
				width={{ base: 730 }}
				pt={32}
				pb={48}
				bgcolor={COLORS.primary.white}
				display="flex"
				flexDirection="column"
				borderRadius={3}
				position="relative"
				px={40}
				boxSizing="border-box"
			>
				{loading && (
					<ActivityIndicator
						size="large"
						color={COLORS.primary.lightBlue}
						style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 100 }}
					/>
				)}
				<IconButton
					sx={{ position: "absolute", top: 16, right: 20 }}
					onClick={onClose}
				>
					<Close fill={COLORS.primary.gray} />
				</IconButton>
				<PrimaryText
					fontSize={24}
					fontWeight={900}
					style={{ textAlign: "center" }}
				>
					Employee Gift
				</PrimaryText>

				<PrimaryText
					fontSize={16}
					color={COLORS.primary.neutral_500}
					style={{ marginTop: 10, marginBottom: 30, textAlign: "center" }}
				>
					Wallet Account Balance: {dollarFormatter.format(rayzeAccountBalance)}
				</PrimaryText>

				<CustomInput
					placeholder="Enter Dollar Amount"
					label="Amount Per Employee"
					required={true}
					onChange={(e) => handleDollarChange(e)}
					value={giftAmount}
					selectTextOnFocus
				/>

				<RadioGroup
					title="Frequency"
					options={[
						{ title: "One-Time" },
						{
							title: "Monthly Recurring",
							caption:
								"(This will override any current monthly gift you have setup)"
						}
					]}
					value={giftFrequency}
					onChange={(e) => setGiftFrequency(e)}
				/>

				<RadioGroup
					title="Employee Selection"
					options={EMPLOYEE_SECTION}
					value={employeeSection}
					onChange={(e) => setEmployeeSection(e)}
				/>

				<Box
					display="flex"
					flexDirection="column"
				>
					{(!showPaymentMethods && !selectedPaymentMethod) ? (
						<Box
							display="flex"
							alignItems="center"
							gap={20}
							mt={11}
							mr={20}
						>
							<Box
								display="flex"
								flexDirection="row"
								bgcolor={
									INSUFFICIENT
										? COLORS.primary.error_50
										: true
											? COLORS.primary.lightestBlue
											: COLORS.primary.white
								}
								border={`1px solid ${INSUFFICIENT
									? COLORS.primary.error
									: true
										? COLORS.primary.lightBlue
										: COLORS.primary.neutral_400
									}`}
								borderRadius={3}
								width={308}
								boxSizing="border-box"
								py={true ? 20 : 9}
								pr={20}
								pl={24}
								justifyContent="space-between"
								sx={{ cursor: !true ? "pointer" : "default" }}
							>
								<LuCoins color={COLORS.primary.darkGray} size={24} />
								<Box
									display="flex"
									flexDirection="row"
									alignItems={"center"}
									justifyContent="space-between"
									width={'100%'}
									ml={12}
								>
									<PrimaryText
										fontSize={14}
										fontWeight={700}
									>
										Rayze Account
									</PrimaryText>
									<PrimaryText
										fontSize={10}
										fontWeight={400}
									>
										{`Balance: ${dollarFormatter.format(rayzeAccountBalance)}`}
									</PrimaryText>
								</Box>

							</Box>
							<Button
								title="Change"
								style={{
									backgroundColor: 'transparent',
									width: undefined,
									paddingVertical: 12,
									paddingHorizontal: 24,
									borderRadius: 100,
									marginTop: 12,
									text: {
										fontSize: 16,
										fontWeight: 900,
										color: COLORS.primary.lightBlue
									},
									// opacity: donateDisabled ? 0.5 : 1,
								}}
								onPress={() => setShowPaymentMethods(true)}
							/>
						</Box>
					) : (
						<PaymentMethodPicker
							title="Payment Method"
							insufficient={INSUFFICIENT}
							selectedPaymentMethod={selectedPaymentMethod}
							setSelectedPaymentMethod={setSelectedPaymentMethod}
							paymentMethods={paymentMethods}
							onChange={(e) => {
								setShowPaymentMethods(false);
								setSelectedPaymentMethod(e)
							}}
							required={true}
							rayzeAccountBalance={rayzeAccountBalance}
							showPaymentMethods={showPaymentMethods}
							setShowPaymentMethods={setShowPaymentMethods}
							setLoading={setLoading}
						/>
					)}
				</Box>

				<PrimaryText
					fontSize={16}
					fontWeight={900}
					style={{ marginTop: 30 }}
				>
					Total:
				</PrimaryText>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					mt={6}
				>
					<Box
						display="flex"
						flexDirection="row"
						alignItems="flex-end"
						gap={5}
					>
						<PrimaryText
							fontSize={32}
							fontWeight={900}
						>
							{!!giftTotal && employeeSection === EMPLOYEE_SECTION[0].title
								? `$${giftTotal.toLocaleString()}`
								: "TBD"}
						</PrimaryText>

						{!!giftTotal && (
							<PrimaryText
								fontSize={20}
								color={COLORS.primary.neutral_500}
								style={{ marginBottom: 5 }}
							>
								(${giftAmount} per employee)
							</PrimaryText>
						)}
					</Box>

					<Button
						disabled={!giftAmount || INSUFFICIENT}
						title="Next"
						onPress={() => onNext?.(employeeSection)}
						iconRight={
							<LuChevronRight
								color={COLORS.primary.white}
								size={20}
							/>
						}
						style={{
							paddingHorizontal: 24,
							paddingVertical: 12,
							text: {
								fontSize: 16,
								fontWeight: 900
							}
						}}
					/>
				</Box>
			</Box>
		</Modal>
	);
}

const CustomInput = ({ label, required, ...props }) => (
	<Box
		display="flex"
		flexDirection="column"
		mb={20}
	>
		<PrimaryText
			fontSize={16}
			fontWeight={900}
		>
			{label}
			{!!required && <span style={{ color: COLORS.primary.error }}>*</span>}
		</PrimaryText>
		<TextInput
			{...props}
			style={styles.textInput}
		/>
	</Box>
);

const RadioGroup = ({ title, options, value, onChange }) => (
	<FormControl
		sx={{ mb: 30 }}
		fullWidth
	>
		<PrimaryText
			fontSize={16}
			fontWeight={900}
			color={COLORS.primary.darkBlue}
			style={{ marginBottom: 4 }}
		>
			{title}
			<span style={{ color: COLORS.primary.error }}>*</span>
		</PrimaryText>

		{options?.map((option) => (
			<Box
				key={option.title}
				display="flex"
				alignItems="center"
				onClick={() => onChange?.(option.title)}
				sx={{ cursor: "pointer" }}
			>
				<Radio
					checked={value === option.title}
					sx={{
						color: COLORS.primary.lightBlue,
						"& .MuiSvgIcon-root": {
							fontSize: 20
						}
					}}
					value={value}
					name={option.title}
				/>

				<PrimaryText
					fontSize={16}
					fontWeight={600}
				>
					{option.title}
				</PrimaryText>

				{!!option?.caption && (
					<PrimaryText
						fontSize={16}
						fontWeight={400}
						color={COLORS.primary.neutral_500}
					>
						{" "}
						{option.caption}
					</PrimaryText>
				)}
			</Box>
		))}
	</FormControl>
);

const styles = StyleSheet.create({
	textInput: {
		marginTop: 12,
		height: 30,
		width: 346,
		borderRadius: 15,
		borderWidth: 0.5,
		borderColor: COLORS.primary.neutral_400,
		fontSize: 14,
		paddingHorizontal: 14
	}
});
