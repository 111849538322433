import { Alert, Box, ButtonBase, IconButton, Modal, Table, TableBody } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ArrowRight, CheckCircle, Close, Politics } from "assets/svgs";
import PaymentMethodCard from "components/Cards/PaymentMethodCard";
import { Button } from "components/Common/Button";
import { PrimaryText } from "components/Common/PrimaryText";
import PaymentMethodPicker from "components/Inputs/PaymentMethodPicker";
import { COLORS } from "constants/Colors";
import { useBusiness } from "context/Business/BusinessContext";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { useOrganization } from "context/OrganizationContext";
import React, { useRef, useState } from "react";
import { LuPlusCircle } from "react-icons/lu";
import { ActivityIndicator, StyleSheet, TextInput, TouchableOpacity, View } from "react-native-web";
import { functions } from "utils/firebase";
import { dollarFormatter } from "utils/helpers";

export default function DepositFundsModal({ isOpen, onClose, rayzeAccountBalance }) {

	const stripe = useStripe();
	const elements = useElements();
	const { selectedOrg } = useOrganization();
	const { paymentMethods, fetchPaymentMethods } = useBusiness();
	const textInputRef = useRef(null);

	const bankAccounts = paymentMethods.filter((method) => method.us_bank_account);

	// console.log('bankAccounts', paymentMethods);

	const [recurring, setRecurring] = useState(false);
	const [amount, setAmount] = useState(0);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(bankAccounts?.length ? bankAccounts[0] : null);
	const [showPaymentMethods, setShowPaymentMethods] = useState(false);

	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const [loading, setLoading] = useState(false);
	const [clientSecret, setClientSecret] = useState(null);
	const [getNewPaymentMethod, setGetNewPaymentMethod] = useState(false);

	function onAmountChange(text) {
		//change to number
		setAmount(Number(text.replace(/[^0-9.]/g, "")))
	}

	async function depositFunds() {
		setLoading(true);
		try {
			const paymentIntentResponse = await functions.httpsCallable(
				'createBankPaymentIntentForWallet',
			)({
				amount,
				customerId: selectedOrg?.id,
				customerName: selectedOrg?.name,
				recurring,
				stripeCustomerId: selectedOrg?.stripeCustomerId,
				selectedPaymentMethodId: selectedPaymentMethod?.id,
			});

			const {
				succeeded,
				clientSecret,
				paymentId = null,
				finalFee,
				paymentIntent: paymentIntentFromCall,
				paymentMethod
			} = paymentIntentResponse.data;
			console.log('createBankPaymentIntent: ', paymentIntentFromCall);

			if (succeeded) {
				setLoading(false);
				setClientSecret(clientSecret);
				setShowConfirmModal(true);
			} else {
				window.alert('Error creating payment intent');
				setLoading(false);
			}


		} catch (err) {
			console.log('Payment error occurred!!! ', err);
			window.alert(
				'Sorry4, an error has occurred processing your payment. Please try again later.',
			);
		}
		// const success = depositFundsSuccess(rayzeAccount, amount)
		// success.then(() => {
		// 	onClose();
		// 	setAmount(0);
		// })
		// 	.catch((e) => {
		// 		console.log('error', e)
		// 	})
	}

	async function confirmDeposit() {
		setLoading(true);
		try {
			const { error, paymentIntent } = await stripe.confirmPayment({
				clientSecret,
				confirmParams: {
					return_url: window.location.href,
				},
				redirect: 'if_required',
			})
			setLoading(false);
			if (error) {
				window.alert(`Error code: ${error.code}`, error.message);
			} else if (paymentIntent) {
				console.log('Success', paymentIntent);
				setShowConfirmModal(false);
				if (paymentIntent.status === 'processing') {
					setShowSuccessModal({
						title: 'Thank you!',
						description: "Your deposit is currently processing and will be available in 3-5 business days.",
					});
				} else if (
					paymentIntent.status === 'requiresAction' &&
					paymentIntent?.nextAction?.type === 'verifyWithMicrodeposits'
				) {
					setShowSuccessModal({
						title: 'Awaiting verification',
						description: 'Your bank requires additional verification. Please check your email for instructions.',
					});
					// The payment must be verified with `verifyMicrodepositsForPayment`
				} else {
					// Alert.alert('Payment status:', paymentIntent.status);
					setShowSuccessModal({
						title: 'Success',
						description: 'Money has been deposited into your account',
					});
				}
			}
		} catch (e) {
			console.log('Error', e);
			window.alert('Error', e.message);
		}
	}

	async function openStripeCustomerPortal() {
		setLoading(true);

		try {
			const paymentIntentResponse = await functions.httpsCallable(
				'createBankSetupIntent',
			)({
				stripeCustomerId: selectedOrg?.stripeCustomerId,
			});

			const {
				succeeded,
				clientSecret,
			} = paymentIntentResponse.data;

			const { setupIntent, error } = await stripe.collectBankAccountForSetup({
				clientSecret,
				params: {
					payment_method_type: 'us_bank_account',
					payment_method_data: {
						billing_details: {
							name: selectedOrg?.name,
							email: selectedOrg?.adminEmail,
						},
					},
				}
			});

			if (error) {
				window.alert(`Error code: ${error.code}`, error.message);
				// setLoading(false);
			} else if (setupIntent) {
				console.log('Payment status:', setupIntent.status);
				// setLoading(false);
				if (setupIntent.status === 'requires_confirmation') {
					const result = await stripe.confirmUsBankAccountSetup(setupIntent.client_secret)
					if (result.error) {
						console.log('Error', result.error);
						window.alert(`Error code: ${result.error.code}`, result.error.message);
					} else {
						fetchPaymentMethods();
					}
				} else {
					fetchPaymentMethods();
				}

			}

			setLoading(false);

		} catch (err) {
			console.log('Payment error occurred!!! ', err);
			window.alert(
				'Sorry4, an error has occurred processing your payment. Please try again later.',
			);
		}
	}

	function closeModal() {
		setShowConfirmModal(false);
		setShowSuccessModal(false);
		setClientSecret(null);
		setAmount(0);
		onClose();
	}

	const textWidth = Math.min(Math.max(String(amount).length, 2), 50) + "ch";

	return (
		<Modal
			open={isOpen}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			onClose={closeModal}
		>

			<Box
				width={500}
				pt={32}
				pb={48}
				bgcolor={COLORS.primary.white}
				display="flex"
				alignItems="center"
				flexDirection="column"
				borderRadius={3}
				position="relative"
			>
				{loading && (
					<ActivityIndicator
						size="large"
						color={COLORS.primary.lightBlue}
						style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 100 }}
					/>
				)}
				<IconButton
					sx={{ position: "absolute", top: 16, right: 20 }}
					onClick={closeModal}
				>
					<Close fill={COLORS.primary.gray} />
				</IconButton>


				<PrimaryText
					fontSize={24}
					fontWeight={900}
				>
					{!!showSuccessModal ? showSuccessModal?.title : "Deposit Funds"}
				</PrimaryText>

				{!showConfirmModal && !showSuccessModal && (
					<>

						<PrimaryText
							fontSize={16}
							color={COLORS.primary.neutral_500}
							style={{ marginTop: 10, marginBottom: 30 }}
						>
							Current Balance: {dollarFormatter.format(rayzeAccountBalance)}
						</PrimaryText>

						<PrimaryText
							fontSize={24}
							fontWeight={900}
						>
							Enter Amount
						</PrimaryText>

						<PrimaryText
							fontSize={14}
							fontWeight={500}
							color={COLORS.primary.error}
							style={{ textAlign: 'center', paddingHorizontal: 24 }}
						>
							This is a nonrefundable, tax deductible contribution to your Rayze Wallet and cannot be withdrawn.
						</PrimaryText>

						<Box
							display="flex"
							alignItems="flex-start"
						>
							<PrimaryText
								fontSize={32}
								fontWeight={900}
								style={{ marginTop: 14 }}
							>
								$
							</PrimaryText>
							<TextInput
								ref={textInputRef}
								style={[styles.textInput, { width: textWidth }]}
								value={amount}
								onChangeText={(text) => onAmountChange(text)}
								selectTextOnFocus
							/>
						</Box>

						<PaymentFrequencyCard
							title="One-Time"
							caption="Single donation"
							amount={amount}
							onClick={() => setRecurring(false)}
							isActive={!recurring}
						/>
						<PaymentFrequencyCard
							title="Monthly Recurring"
							caption="Per month, cancel anytime"
							type="recurring"
							amount={amount}
							onClick={() => setRecurring(true)}
							isActive={recurring}
						/>

						<PrimaryText
							fontSize={16}
							fontWeight={900}
							style={{ alignSelf: 'flex-start', marginLeft: 26, marginTop: 33 }}
						>
							Payment Method<PrimaryText
								fontSize={16}
								fontWeight={900}
								color={COLORS.primary.error}
							// style={{ marginTop: -12 }}
							>
								*
							</PrimaryText>
						</PrimaryText>

						{!showPaymentMethods ? (
							<Box
								display="flex"
								flexDirection={'row'}
								alignItems="center"
								// justifyContent="center"
								marginTop={12}
								width={428}
							>
								<PaymentMethodCard

									isSelected={true}
									// onSelect={() => setSelectedPaymentMethod(item)}
									item={selectedPaymentMethod}
									style={{ width: 428, alignSelf: 'flex-start' }}

								/>
								{/* {bankAccounts.length > 1 && ( */}
								<Button
									title="Change"
									style={{
										backgroundColor: 'transparent',
										width: undefined,
										paddingVertical: 12,
										paddingHorizontal: 24,
										borderRadius: 100,
										marginTop: 6,
										text: {
											fontSize: 16,
											fontWeight: 900,
											color: COLORS.primary.lightBlue
										},
										// opacity: donateDisabled ? 0.5 : 1,
									}}
									onPress={() => setShowPaymentMethods(true)}
								/>
								{/* )} */}
							</Box>
						) : (
							<Table
								className="table"
								style={{ overflow: "hidden", width: 428, marginTop: 12 }}
							>
								<TableBody>
									{bankAccounts.sort((a, b) => a.isDefault ? -1 : 1).map((item, i) => {
										return (
											<PaymentMethodCard
												key={item?.id}

												// isSelected={selectedMethod?.id === item?.id}
												onSelect={() => {
													setSelectedPaymentMethod(item)
													setShowPaymentMethods(false)
												}}
												item={item}
												style={{ width: "100%" }}

											/>
										)
									})}
								</TableBody>
								<ButtonBase
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										alignSelf: "flex-start",
										gap: 8,
										mt: -8,
										ml: 8,
										mb: 12
									}}
									disableRipple
									onClick={openStripeCustomerPortal}
								>
									<LuPlusCircle
										color={COLORS.primary.lightBlue}
										size={20}
									/>
									<PrimaryText
										fontSize={16}
										fontWeight={900}
										color={COLORS.primary.lightBlue}
									>
										Add New
									</PrimaryText>
								</ButtonBase>
							</Table>
						)}


						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-around"
							mt={12}
							width={'100%'}
						>
							<Button
								title="Cancel"
								style={{
									backgroundColor: COLORS.primary.transparent,
									width: undefined,
									borderColor: COLORS.primary.darkGray,
									borderWidth: 1,
									paddingVertical: 12,
									paddingHorizontal: 24,
									borderRadius: 100,
									text: {
										fontSize: 16,
										fontWeight: 900,
										color: COLORS.primary.darkGray
									},
									// opacity: donateDisabled ? 0.5 : 1,
								}}
								onPress={closeModal}
							/>
							<Button
								title="Deposit"
								style={{
									backgroundColor: COLORS.primary.lightBlue,
									width: undefined,
									paddingVertical: 12,
									paddingHorizontal: 24,
									borderRadius: 100,
									text: {
										fontSize: 16,
										fontWeight: 900,
										color: COLORS.primary.white
									},
									// opacity: donateDisabled ? 0.5 : 1,
								}}
								disabled={amount <= 0}
								onPress={depositFunds}
							/>
						</Box>
					</>
				)}

				{showConfirmModal && (
					<>
						<PrimaryText
							fontSize={24}
							fontWeight={500}
							style={{ textAlign: 'center', marginTop: 24 }}
						>
							This is a nonrefundable, tax deductible contribution to you Rayze Wallet and cannot be withdrawn.
						</PrimaryText>
						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-around"
							mt={24}
							width={'100%'}
						>
							<Button
								title="Cancel"
								style={{
									backgroundColor: COLORS.primary.transparent,
									width: undefined,
									borderColor: COLORS.primary.darkGray,
									borderWidth: 1,
									paddingVertical: 12,
									paddingHorizontal: 24,
									borderRadius: 100,
									text: {
										fontSize: 16,
										fontWeight: 900,
										color: COLORS.primary.darkGray
									},
									// opacity: donateDisabled ? 0.5 : 1,
								}}
								onPress={closeModal}
							/>
							<Button
								title="Ok, Deposit Funds"
								style={{
									backgroundColor: COLORS.primary.lightBlue,
									width: undefined,
									paddingVertical: 12,
									paddingHorizontal: 24,
									borderRadius: 100,
									text: {
										fontSize: 16,
										fontWeight: 900,
										color: COLORS.primary.white
									},
									// opacity: donateDisabled ? 0.5 : 1,
								}}
								disabled={!clientSecret}
								onPress={confirmDeposit}
							/>
						</Box>
					</>
				)}

				{!!showSuccessModal && (
					<>
						<PrimaryText fontSize={24} fontWeight={500} style={{ marginTop: 16, textAlign: 'center', paddingHorizontal: 24 }}>
							{showSuccessModal?.description}
						</PrimaryText>
						<Button
							title="Finish"
							style={{
								backgroundColor: COLORS.primary.lightBlue,
								width: undefined,
								paddingVertical: 12,
								paddingHorizontal: 24,
								borderRadius: 100,
								marginTop: 24,
								text: {
									fontSize: 16,
									fontWeight: 900,
									color: COLORS.primary.white
								},
								alignSelf: 'center'
								// opacity: donateDisabled ? 0.5 : 1,
							}}
							onPress={() => {
								setShowConfirmModal(false);
								setShowSuccessModal(false);
								setClientSecret(null);
								setAmount(0);

								closeModal();
							}}
						/>
					</>
				)}


			</Box>

		</Modal>
	);
}

const PaymentFrequencyCard = ({
	title,
	caption,
	amount,
	type,
	isActive,
	onClick
}) => (
	<Box
		display="flex"
		flexDirection="row"
		bgcolor={isActive ? COLORS.primary.lightestBlue : COLORS.primary.neutral}
		border={`1px solid ${isActive ? COLORS.primary.lightBlue : COLORS.primary.neutral_400
			}`}
		borderRadius={3}
		width={428}
		boxSizing="border-box"
		mt={20}
		py={18}
		pr={20}
		pl={isActive ? 24 : 56}
		onClick={onClick}
		justifyContent="space-between"
		sx={{ cursor: "pointer" }}
	>
		<Box
			display="flex"
			alignItems="center"
		>
			{isActive && (
				<CheckCircle
					fill={COLORS.primary.lightBlue}
					width={20}
					height={20}
				/>
			)}
			<Box
				display="flex"
				flexDirection="column"
				gap={4}
				ml={isActive ? 12 : 0}
			>
				<PrimaryText
					fontSize={14}
					fontWeight={700}
				>
					{title}
				</PrimaryText>
				<PrimaryText
					fontSize={10}
					fontWeight={300}
				>
					{caption}
				</PrimaryText>
			</Box>
		</Box>

		<Box
			display="flex"
			alignItems="flex-start"
		>
			<PrimaryText
				fontSize={8}
				fontWeight={700}
				style={{ marginTop: 1, marginRight: 2 }}
			>
				$
			</PrimaryText>
			<PrimaryText
				fontSize={14}
				fontWeight={700}
			>
				{amount}
				{type === "recurring" ? "/mo" : ""}
			</PrimaryText>
		</Box>
	</Box>
);

const styles = StyleSheet.create({
	textInput: {
		fontSize: 72,
		fontWeight: 900,
		// width: 70
	},

});
