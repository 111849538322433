import React from "react";
import { Box, ButtonBase } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import PaymentMethodPickerCard from "components/Cards/PaymentMethodPickerCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { useBusiness } from "context/Business/BusinessContext";
import { useOrganization } from "context/OrganizationContext";
import { LuAlertTriangle, LuCoins, LuPlusCircle } from "react-icons/lu";
import { functions } from "utils/firebase";
import { dollarFormatter } from "utils/helpers";

export default function PaymentMethodPicker({
	onChange,
	insufficient,
	selectedPaymentMethod,
	title,
	boxProps,
	required,
	rayzeAccountBalance,
	paymentMethods,
	showPaymentMethods,
	setShowPaymentMethods,
	setSelectedPaymentMethod,
	setLoading
}) {

	const { selectedOrg } = useOrganization();
	const { fetchPaymentMethods } = useBusiness();
	const stripe = useStripe();

	async function openStripeCustomerPortal() {
		setLoading(true);

		try {
			const paymentIntentResponse = await functions.httpsCallable(
				'createBankSetupIntent',
			)({
				stripeCustomerId: selectedOrg?.stripeCustomerId,
			});

			const {
				succeeded,
				clientSecret,
			} = paymentIntentResponse.data;

			const { setupIntent, error } = await stripe.collectBankAccountForSetup({
				clientSecret,
				params: {
					payment_method_type: 'us_bank_account',
					payment_method_data: {
						billing_details: {
							name: selectedOrg?.name,
							email: selectedOrg?.adminEmail,
						},
					},
				}
			});



			if (error) {
				window.alert(`Error code: ${error.code}`, error.message);
				// setLoading(false);
			} else if (setupIntent) {
				console.log('Payment status:', setupIntent.status);
				// setLoading(false);
				if (setupIntent.status === 'requires_confirmation') {
					const result = await stripe.confirmUsBankAccountSetup(setupIntent.client_secret)
					if (result.error) {
						console.log('Error', result.error);
						window.alert(`Error code: ${result.error.code}`, result.error.message);
					} else {
						fetchPaymentMethods();
					}
				} else {
					fetchPaymentMethods();
				}
			}

			setLoading(false);

		} catch (err) {
			console.log('Payment error occurred!!! ', err);
			window.alert(
				'Sorry4, an error has occurred processing your payment. Please try again later.',
			);
		}
	}

	return (
		<Box {...boxProps}>
			<PrimaryText
				fontSize={16}
				fontWeight={900}
			>
				{title || "Payment Method"}
				{required && <span style={{ color: COLORS.primary.error }}>*</span>}
			</PrimaryText>
			<Box ml={20}>
				{(showPaymentMethods || !selectedPaymentMethod) ? (
					<Box
						display="flex"
						flexDirection="column"
						// gap={20}
						mt={11}
						mr={20}
					>
						<Box
							display="flex"
							flexDirection="row"
							bgcolor={
								insufficient
									? COLORS.primary.error_50
									: !selectedPaymentMethod
										? COLORS.primary.lightestBlue
										: COLORS.primary.white
							}
							border={`1px solid ${insufficient
								? COLORS.primary.error
								: !selectedPaymentMethod
									? COLORS.primary.lightBlue
									: COLORS.primary.neutral_400
								}`}
							borderRadius={3}
							width={308}
							boxSizing="border-box"
							py={9}
							pr={20}
							pl={24}
							mt={12}
							justifyContent="space-between"
							sx={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedPaymentMethod(null)
								setShowPaymentMethods(false)
							}}
						>
							<LuCoins color={COLORS.primary.darkGray} size={24} />
							<Box
								display="flex"
								flexDirection="row"
								alignItems={"center"}
								justifyContent="space-between"
								width={'100%'}
								ml={12}
							>
								<PrimaryText
									fontSize={14}
									fontWeight={700}
								>
									Rayze Account
								</PrimaryText>
								<PrimaryText
									fontSize={10}
									fontWeight={400}
								>
									{`Balance: ${dollarFormatter.format(rayzeAccountBalance)}`}
								</PrimaryText>
							</Box>

						</Box>
						{insufficient && (
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								gap={4}
								my={4}
							>
								<LuAlertTriangle color={COLORS.primary.error} size={20} />
								<PrimaryText
									fontSize={12}
									style={{ color: COLORS.primary.error }}
								>
									{`Insufficient funds, choose a different account`}
								</PrimaryText>
							</Box>
						)}
					</Box>
				) : (
					<PaymentMethodPickerCard
						item={selectedPaymentMethod}
						selectable={false}
						onChange={() => {
							console.log('setShowPaymentMethods', showPaymentMethods);
							setShowPaymentMethods(true)
						}}
					/>
				)}
				<>
					{(showPaymentMethods && paymentMethods?.length > 0) &&
						paymentMethods.map((item, index) => (
							<PaymentMethodPickerCard
								key={index}
								item={item}
								selectable={true}
								// active={item.id === selectedPaymentMethod?.id}
								onChange={() => onChange?.(item)}
							/>
						))}
				</>



				{!selectedPaymentMethod && (
					<ButtonBase
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							alignSelf: "flex-start",
							gap: 8,
							mt: 18
						}}
						disableRipple
						onClick={openStripeCustomerPortal}
					>
						<LuPlusCircle
							color={COLORS.primary.lightBlue}
							size={20}
						/>
						<PrimaryText
							fontSize={16}
							fontWeight={900}
							color={COLORS.primary.lightBlue}
						>
							Add New
						</PrimaryText>
					</ButtonBase>
				)}


			</Box>
		</Box>
	);
}