import { Box, IconButton, Modal } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { Close } from "assets/svgs";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { useOrganization } from "context/OrganizationContext";
import React, { useState } from "react";
import { LuChevronLeft } from "react-icons/lu";
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from "react-native-web";
import { giftFundsToEmployeeSuccess } from "services/businesses/business-wallet-service";
import { functions } from "utils/firebase";
import { dollarFormatter } from "utils/helpers";


export default function EmployeeGiftConfirmationModal({
	isOpen,
	onClose,
	onConfirm,
	onBack,
	rayzeAccountBalance,
	giftTotal,
	giftAmount,
	giftFrequency,
	selectedEmployees,
	allEmployees,
	numberOfEmployees,
	selectedPaymentMethod,
	setShowSuccessModal,
	showSuccessModal
}) {
	const stripe = useStripe();
	const { selectedOrg } = useOrganization();
	const { rayzeAccount } = useBusinessDonations();

	const [loading, setLoading] = useState(false);

	function giftFunds() {
		if (selectedPaymentMethod) {
			depositFunds();
		} else {
			// return console.log('test')
			giftFundsToEmployeeSuccess(rayzeAccount, giftTotal, selectedEmployees)
				.then(() => {
					onConfirm();
				})
				.catch((e) => {
					console.log('error', e)
				})
		}
	}

	async function depositFunds() {
		setLoading(true);
		try {
			//stringify the employee ids
			const employeeIds = selectedEmployees.map((employee) => employee.stripeCustomerId).join(",");

			const paymentIntentResponse = await functions.httpsCallable(
				'createBankPaymentIntentForWallet',
			)({
				amount: giftTotal,
				customerId: selectedOrg?.id,
				customerName: selectedOrg?.name,
				recurring: giftFrequency === 'One-Time' ? false : true,
				stripeCustomerId: selectedOrg?.stripeCustomerId,
				selectedMethodId: selectedPaymentMethod?.id,
				employeeGiftIds: employeeIds,
			});

			const {
				succeeded,
				clientSecret,
				paymentId = null,
				finalFee,
				paymentIntent: paymentIntentFromCall,
				paymentMethod
			} = paymentIntentResponse.data;
			console.log('createBankPaymentIntent: ', paymentIntentFromCall);

			if (succeeded) {
				console.log('Payment intent created successfully');
				confirmDeposit(clientSecret);
			} else {
				window.alert('Error creating payment intent');
				setLoading(false);
			}


		} catch (err) {
			console.log('Payment error occurred!!! ', err);
			window.alert(
				'Sorry4, an error has occurred processing your payment. Please try again later.',
			);
		}
		// const success = depositFundsSuccess(rayzeAccount, amount)
		// success.then(() => {
		// 	onClose();
		// 	setAmount(0);
		// })
		// 	.catch((e) => {
		// 		console.log('error', e)
		// 	})
	}

	async function confirmDeposit(clientSecret) {
		setLoading(true);
		try {
			const { error, paymentIntent } = await stripe.confirmPayment({
				clientSecret,
				confirmParams: {
					return_url: window.location.href,
				},
				redirect: 'if_required',
			})
			setLoading(false);
			if (error) {
				window.alert(`Error code: ${error.code}`, error.message);
			} else if (paymentIntent) {
				console.log('Success', paymentIntent);
				if (paymentIntent.status === 'processing') {
					setShowSuccessModal({
						type: "success",
						title: 'Thank you!',
						description: "Your deposit is currently processing and will be available in 3-5 business days.",
					});
				} else if (
					paymentIntent.status === 'requiresAction' &&
					paymentIntent?.nextAction?.type === 'verifyWithMicrodeposits'
				) {
					setShowSuccessModal({
						type: "success",
						title: 'Awaiting verification',
						description: 'Your bank requires additional verification. Please check your email for instructions.',
					});
					// The payment must be verified with `verifyMicrodepositsForPayment`
				} else {
					// Alert.alert('Payment status:', paymentIntent.status);
					setShowSuccessModal({
						type: "success",
						title: 'Success',
						description: 'Money has been deposited into your account',
					});
				}
			}
		} catch (e) {
			console.log('Error', e);
			window.alert('Error', e.message);
		}
	}

	let desc = "Rayze Wallet"
	if (selectedPaymentMethod) {
		const { card = null, us_bank_account = null } = selectedPaymentMethod;
		const account = card ? card : us_bank_account;
		const { brand = "Bank Account", last4 } = account;
		desc = "Bank Acct " + last4;
	}


	return (
		<Modal
			open={isOpen}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			onClose={onClose}
		>
			<Box
				width={{ base: 704 }}
				pt={32}
				pb={24}
				bgcolor={COLORS.primary.white}
				display="flex"
				flexDirection="column"
				borderRadius={3}
				position="relative"
				px={90}
				boxSizing="border-box"
			>
				{loading && (
					<ActivityIndicator
						size="large"
						color={COLORS.primary.lightBlue}
						style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 100 }}
					/>
				)}
				<IconButton
					sx={{ position: "absolute", top: 16, right: 20 }}
					onClick={onClose}
				>
					<Close fill={COLORS.primary.gray} />
				</IconButton>
				<PrimaryText
					fontSize={24}
					fontWeight={900}
					style={{ textAlign: "center" }}
				>
					Employee Gift
				</PrimaryText>

				<PrimaryText
					fontSize={16}
					color={COLORS.primary.neutral_500}
					style={{ marginTop: 10, marginBottom: 30, textAlign: "center" }}
				>
					Current Balance: {dollarFormatter.format(rayzeAccountBalance)}
				</PrimaryText>

				<Box
					minWidth={526}
					bgcolor={COLORS.primary.neutral}
					borderRadius="25px"
					pt={49}
					pb={40}
					display="flex"
					flexDirection="column"
					alignItems="center"
					boxShadow="5px 5px 15px 0 rgba(128, 128, 128, 0.15)"
				>
					<PrimaryText
						fontSize={24}
						fontWeight={400}
					>
						Summary
					</PrimaryText>

					<PrimaryText
						fontSize={32}
						fontWeight={900}
						style={{ marginTop: 23 }}
					>
						{allEmployees ? numberOfEmployees : selectedEmployees?.length} Employees Selected
					</PrimaryText>

					<PrimaryButton
						title="View/Edit Selection"
						titleStyle={{ fontSize: 14, fontWeight: 700 }}
						style={{ height: 29, paddingHorizontal: 16, marginTop: 15 }}
					/>

					<View style={styles.line} />

					<PrimaryText
						fontSize={32}
						fontWeight={900}
					>
						{giftFrequency} Gift
					</PrimaryText>

					<View style={styles.line} />

					<PrimaryText
						fontSize={32}
						fontWeight={900}
					>
						{dollarFormatter.format(giftTotal)} Total
					</PrimaryText>

					<PrimaryText
						fontSize={20}
						fontWeight={400}
						color={COLORS.primary.neutral_500}
					>
						({dollarFormatter.format(giftAmount)} per employee)
					</PrimaryText>

					<View style={styles.line} />

					<PrimaryText
						fontSize={40}
						fontWeight={900}
					>
						{desc}
					</PrimaryText>
				</Box>

				{/* Buttons */}


				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="center"
					gap={44}
					mt={36}
				>
					<PrimaryButton
						title="Back"
						variant="outlined"
						onPress={onBack}
						style={{ borderRadius: 20, paddingHorizontal: 24 }}
						icon={
							<LuChevronLeft
								size={20}
								color={COLORS.primary.darkBlue}
							/>
						}
					/>
					<PrimaryButton
						title="Confirm"
						style={{ borderRadius: 20, paddingHorizontal: 24 }}
						onPress={giftFunds}
						disabled={giftAmount <= 0}
					/>
				</Box>
			</Box>
		</Modal>
	);
}

const styles = StyleSheet.create({
	line: {
		marginVertical: 22,
		width: 187,
		height: 1,
		backgroundColor: COLORS.primary.neutral_400
	}
});
