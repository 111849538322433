import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { useOrganization } from "context/OrganizationContext";
import { useEvents } from "context/EventContext";
import { NavHeader } from "components/Headers/NavHeader";
import { useNavigate } from "react-router-dom";
import { dollarFormatter, getUsersName } from "utils/helpers";
import {
	checkInEventAttendee,
	getEventAttendees,
	undoCheckInEventAttendee
} from "services/events-service";
import { ActivityIndicator } from "react-native-web";
import { EventAttendeeCard } from "components/Cards/EventAttendeeCard";
import { FlyersPicker } from "components/Flyers/FlyersPicker";
import { EventCreationModal } from "components/Modals/EventCreationModal";
import MuiButton from "components/Common/MuiButton";
import { Edit } from "assets/svgs";
import moment from "moment";
import { Cause } from "components/CausesSkills/SelectCauses";
import { causes } from "constants/Data";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomInput } from "components/Inputs/CustomInput";
import MuiSelect from "components/Inputs/MuiSelect";
import MuiTablePagination from "components/Common/MuiTablePagination";
import ShiftStartTime from "components/Dropdowns/ShiftStartTime";
import { ButtonBase } from "@mui/material";
import { DonationCard } from "components/Cards/DonationCard";

export function EventDetail() {
	const navigate = useNavigate();
	const { members, userType, selectedOrg } = useOrganization();
	const { selectedEvent, setSelectedEvent, eventDonations } = useEvents();

	// States
	const [showEditEventModal, setShowEditEventModal] = useState(false);
	const [details, setDetails] = useState({ ...selectedEvent });
	const [selectedCauses, setSelectedCauses] = useState([]);
	const [newEventCoverPhotos, setNewEventCoverPhotos] = useState([
		selectedEvent?.coverPhoto
	]);
	const [disabled, setDisabled] = useState(true);
	const [going, setGoing] = useState([]);
	const [interested, setInterested] = useState([]);
	const [loading, setLoading] = useState(false);
	const [activeView, setActiveView] = useState("All");
	const [selectedStartTimes, setSelectedStartTimes] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState("");
	const [activeTab, setActiveTab] = useState("Volunteers");

	const eventDetailsLeft = [
		{
			title: "Type",
			value: selectedEvent?.mode
		},
		{
			title: "Location",
			value:
				selectedEvent?.mode === "In-Person"
					? `${selectedEvent?.address?.line1}\n${selectedEvent?.address?.city}, ${selectedEvent?.address?.state}, ${selectedEvent?.address?.zipCode} `
					: "Virtual Event",
			capitalize: true
		},
		{
			title: "Date",
			value: moment(selectedEvent?.data).format("DD/MM/YYYY")
		},
		{
			title: "Time",
			value:
				moment(selectedEvent?.startTime).format("hh:mmA") +
				" - " +
				moment(selectedEvent?.endTime).format("hh:mmA")
		},
		{
			title: "Creator",
			value: getUsersName(members.find((x) => x.id === selectedEvent?.creator))
		}
	];

	const eventDetailsRight = [
		{
			title: "Volunteer Numbers",
			value: selectedEvent?.volunteersNeeded
				? `${selectedEvent?.volunteersNeeded} spots`
				: selectedEvent?.shifts?.length
					? `${selectedEvent?.shifts?.length
					} Shifts | ${selectedEvent?.shifts?.reduce(
						(total, currentShift) => total + currentShift?.spots,
						0
					) || "Unlimited"} Spots`
					: "Unlimited"
		},
		{
			title: "Description",
			value: selectedEvent?.about
		},
		{
			title: "Causes",
			value: selectedEvent?.categories?.map((category) => {
				const { name, icon } =
					causes?.find(({ value }) => value === category) || {};
				return (
					<Cause
						{...{ name, icon, isActive: true }}
						containerStyle={{
							marginRight: 20
						}}
					/>
				);
			})
		}
	];

	// Filter start times from shifts
	const startTimes = useMemo(() => {
		let times =
			selectedEvent?.shifts?.map((shift) =>
				moment(shift?.startTime?.toDate()).format("hh:00 a")
			) || [];
		times = [...new Set(times)];

		return times;
	}, [selectedEvent]);

	const attendeesActiveView = useMemo(
		() => [
			...(activeView === "Going" && going?.length
				? going
				: activeView === "Interested" && interested?.length
					? interested
					: activeView === "All"
						? [
							...(going?.length ? going : []),
							...(interested?.length ? interested : [])
						]
						: [])
		],
		[activeView, interested, going]
	);

	// Filtered data based on search text, going, interested and start time
	const attendeesAfterFilter = useMemo(() => {
		let attendees = attendeesActiveView;

		if (search)
			attendees = attendees.filter((attendee) =>
				getUsersName(attendee)
					?.toLocaleLowerCase()
					?.includes(search.toLowerCase())
			);

		const currentPage = page + 1;
		const start = rowsPerPage * currentPage - rowsPerPage;
		const end = rowsPerPage * currentPage;

		attendees = attendees.slice(start, end);

		return attendees;
	}, [rowsPerPage, page, search, attendeesActiveView]);

	useEffect(() => {
		if (!selectedEvent) return;
		setDetails({ ...selectedEvent });

		const sub = getEventAttendees(selectedEvent.id, gotProfiles);
		return () => {
			sub();
		};
	}, [selectedEvent]);

	function gotProfiles(profiles) {
		const going = profiles.filter((x) => x.going);
		setGoing(going);
		const interested = profiles.filter((x) => x.interested && !x.going);
		setInterested(interested);
	}

	useEffect(() => {
		(details.mode &&
			(details.mode === "In-Person"
				? details.address.line1 &&
				details.address.city &&
				details.address.state &&
				details.address.zipCode &&
				details.latLng
				: details.meetingInfo) &&
			details.name !== "" &&
			details.about !== "" &&
			details.endTime > details.startTime &&
			selectedCauses?.length > 0 &&
			newEventCoverPhotos.length > 0 &&
			!shallowEqual(selectedCauses, selectedEvent?.causes)) ||
			!shallowEqual(details, selectedEvent) ||
			!shallowEqual(newEventCoverPhotos, [selectedEvent?.coverPhoto])
			? setDisabled(false)
			: setDisabled(true);
	}, [details, newEventCoverPhotos, selectedCauses, selectedEvent]);

	useEffect(() => {
		if (details.mode === "Virtual") {
			setDetails({
				...details,
				address: selectedOrg?.address,
				latLng: selectedOrg?.latLng
			});
		}
	}, [details, selectedOrg]);

	const shallowEqual = (objA, objB) => {
		if (!objA || !objB) {
			return objA === objB;
		}
		return !Boolean(
			Object.keys(Object.assign({}, objA, objB)).find(
				(key) => objA[key] !== objB[key]
			)
		);
	};

	function onBack() {
		setSelectedEvent(null);
		navigate(-1);
	}

	const handleCheckIn = (user) => {
		if (!user.attended) {
			setLoading(true);
			checkInEventAttendee(selectedEvent.id, user.userId, onCheckedIn, onError);
		} else {
			let confirm = window.confirm(
				"User Previously Checked In, do you want to mark this user as not checked in?"
			);
			if (confirm) {
				setLoading(true);
				undoCheckInEventAttendee(selectedEvent.id, user.userId, onError);
			} else {
				onError();
			}
		}
	};

	const onCheckedIn = () => {
		setLoading(false);

		window.alert("Volunteer checked in successfully");
	};
	function onError() {
		setLoading(false);
	}

	const totalDonations = eventDonations.reduce(
		(total, donation) => total + (donation.amount - (donation?.fee ? donation.fee : 0)),
		0
	);

	let tabs = ["Volunteers"]
	if (selectedOrg?.type !== "community") {
		tabs.push("Donors")
	}

	return (
		<View style={styles.container}>
			<NavHeader
				title={selectedEvent?.name}
				onBack={onBack}
			/>

			<View style={[styles.info, { marginTop: 24, flex: 1 }]}>
				<Image
					style={styles.cover_img_v}
					source={{ uri: selectedEvent?.coverPhoto }}
					resizeMode={"contain"}
				/>

				<View style={{ flex: 1 }}>
					{/** DOWNLOADS */}

					<PrimaryText
						fontSize={24}
						fontWeight={800}
						style={{ marginBottom: 20 }}
					>
						Share Event
					</PrimaryText>

					<FlyersPicker
						selectedEvent={selectedEvent}
						selectedOrg={selectedOrg}
						isEvent={true}
					/>
				</View>
			</View>

			<View style={styles.info}>
				<View style={styles.infoContainer}>
					<Box
						display="flex"
						alignItems="center"
						mb={15}
					>
						<PrimaryText
							fontSize={24}
							fontWeight={900}
						>
							Event Details
						</PrimaryText>

						{userType === "Leader" ? (
							<MuiButton
								title="Edit"
								variant="outlined"
								color="secondary"
								sx={{ marginLeft: 28 }}
								startIcon={<Edit fill={COLORS.primary.darkBlue} />}
								onPress={() => setShowEditEventModal(!showEditEventModal)}
							/>
						) : null}
					</Box>

					{eventDetailsLeft.map((eventDetail, i) => {
						const { title, value } = eventDetail;
						return (
							<View key={i}>
								<PrimaryText
									fontSize={16}
									fontWeight={800}
									style={{ width: 180, textAlign: "left", marginBottom: 4 }}
								>
									{title}
								</PrimaryText>
								<PrimaryText
									fontSize={14}
									fontWeight={400}
									style={[
										{ marginBottom: 12 },
										eventDetail?.capitalize && { textTransform: "capitalize" }
									]}
								>
									{value}
								</PrimaryText>
							</View>
						);
					})}
				</View>

				<View style={styles.infoContainer}>
					{eventDetailsRight.map((eventDetail, i) => {
						const { title, value } = eventDetail;
						return (
							<View key={i}>
								<PrimaryText
									fontSize={16}
									fontWeight={800}
									style={{ width: 180, textAlign: "left", marginBottom: 4 }}
								>
									{title}
								</PrimaryText>
								<PrimaryText
									fontSize={14}
									fontWeight={400}
									style={[
										{ marginBottom: 12 },
										eventDetail?.capitalize && { textTransform: "capitalize" }
									]}
								>
									{value}
								</PrimaryText>
							</View>
						);
					})}
				</View>

				{selectedOrg?.type === "nonprofit" && (
					<View style={[styles.infoContainer, { flexDirection: "row" }]}>
						<View style={styles.line} />

						<View style={styles.donationRaised}>
							<PrimaryText
								fontSize={24}
								fontWeight={600}
								style={{ color: COLORS.primary.lightBlue }}
							>
								{dollarFormatter.format(totalDonations)}
							</PrimaryText>
							<PrimaryText fontSize={17}>Dollars Raised</PrimaryText>
						</View>
					</View>
				)}
			</View>

			{/* Tab & Table */}
			<Box
				width="100%"
				mt={0}
			>
				{/* Tabs */}
				<Box display="flex">
					{tabs.map((title, index) => (
						<Tab
							isActive={activeTab === title}
							title={title}
							onClick={setActiveTab}
							key={title + index}
						/>
					))}
				</Box>

				<ShadowContainer style={{ marginBottom: 50 }}>

					{activeTab === "Volunteers" && (
						<>
							<Box
								display="flex"
								alignItems="center"
								width="100%"
								mb={40}
								mt={14}
							>
								<CustomInput
									placeholder="Search"
									style={{
										flexGrow: 1,
										maxWidth: 350,
										marginRight: 20
									}}
									value={search}
									onChangeText={setSearch}
								/>

								<MuiSelect
									label="View"
									options={["Going", "Interested", "All"]}
									value={activeView}
									onSelect={setActiveView}
									labelStyle={{
										fontSize: 14,
										fontWeight: 900
									}}
									horizontal
									sx={{ width: 140 }}
								/>

								<ShiftStartTime
									onChange={setSelectedStartTimes}
									value={selectedStartTimes}
									options={startTimes}
								/>
							</Box>

							{/** TABLE */}
							<Table
								className="table"
								style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
							>
								<TableHead>
									<TableRow>
										<TableCell style={{ textAlign: "left" }}>NAME</TableCell>
										<TableCell>EMAIL</TableCell>
										<TableCell>USERNAME</TableCell>
										<TableCell>SHIFT</TableCell>
										<TableCell>TIME</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{attendeesAfterFilter?.map((item, i) => (
										<EventAttendeeCard
											user={item}
											key={i}
											onPress={handleCheckIn}
										/>
									))}
								</TableBody>
							</Table>
						</>
					)}

					{activeTab === "Donors" && (
						<DonationHistoryTable
							data={eventDonations}
							loading={loading}
						/>
					)}



					<MuiTablePagination
						count={attendeesAfterFilter?.length || 0}
						rowsPerPage={10}
						page={page}
						setCurrentPage={setPage}
						onRowsPerPageChange={(e) => {
							setRowsPerPage(e.target.value);
							setPage(0);
						}}
					/>
				</ShadowContainer>
			</Box>

			{/** NEW EVENT */}
			{showEditEventModal && (
				<EventCreationModal
					open={showEditEventModal}
					onClose={() => setShowEditEventModal(false)}
					event={selectedEvent}
					editing={true}
				/>
			)}
			{loading && (
				<ActivityIndicator
					animating={loading}
					size={"large"}
					style={styles.activity}
					color={COLORS.primary.lightBlue}
				/>
			)}
		</View>
	);
}

const Tab = ({ title, isActive, onClick }) => (
	<ButtonBase
		sx={{
			ml: 40,
			boxShadow: isActive ? "5px 5px 15px rgba(128, 128, 128, 0.15)" : "",
			borderRadius: "10px 10px 0 0",
			overflow: "hidden"
		}}
		onClick={() => onClick(title)}
	>
		<Box
			pt={8}
			pb={10}
			px={25}
			bgcolor={isActive ? COLORS.primary.neutral : COLORS.primary.neutral_200}
		>
			<PrimaryText
				fontSize={24}
				fontWeight={900}
			>
				{title}
			</PrimaryText>
		</Box>
	</ButtonBase>
);

const DonationHistoryTable = ({ data, loading }) => (
	<Table className="table">
		<TableHead>
			<TableRow>
				<TableCell></TableCell>
				<TableCell style={{ textAlign: "left" }}>Name</TableCell>
				<TableCell>Payment ID</TableCell>
				<TableCell>Email</TableCell>
				<TableCell>Frequency</TableCell>
				<TableCell>Amount</TableCell>
				<TableCell>Date</TableCell>
			</TableRow>
		</TableHead>

		{/* {!loading && ( */}
		<TableBody>
			{data?.map((el, i) => (
				<DonationCard
					item={el}
					key={el?.id || i}
				// onPress={() => userSelected(el)}
				/>
			))}
		</TableBody>
		{/* )} */}
	</Table>
);

const styles = StyleSheet.create({
	activity: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.primary.white,
		position: "absolute",
		top: 0,
		bottom: 0,
		zIndex: 999
	},
	container: {
		flex: 1,
		width: "100%",
		paddingTop: 20,
		backgroundColor: COLORS.primary.white
	},
	header: {
		color: COLORS.primary.darkBlue
	},
	cover_img_v: {
		width: "40%",
		aspectRatio: 16 / 9,
		borderRadius: 12,
		marginRight: 40
	},
	stats: {
		flexDirection: "row"
	},
	statContainer: {
		padding: 12,
		marginHorizontal: 24,
		width: "28%",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12
	},
	description: {
		marginVertical: 24,
		padding: 27,
		backgroundColor: COLORS.primary.offWhite,
		borderRadius: 12,
		marginBottom: 16
	},
	info: {
		flexDirection: "row",
		marginBottom: 16
	},
	infoContainer: {
		flex: 1,
		padding: 10
	},
	infoTextRow: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 6
	},
	causesContainer: {
		flex: 1,
		padding: 20,
		borderRadius: 12,
		marginLeft: 16
	},
	causes: {
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		paddingBottom: 20,
		paddingTop: 12
	},
	cause: {
		flexDirection: "row",
		alignItems: "center",
		paddingVertical: 4,
		paddingHorizontal: 8,
		marginRight: 8,
		marginBottom: 10,
		borderRadius: 100,
		backgroundColor: COLORS.primary.lightBlue
	},
	tabs: {
		flexDirection: "row",
		alignItems: "flex-start"
	},
	tabsButton: {
		width: 220,
		height: 36,
		backgroundColor: COLORS.primary.offWhite,
		borderTopLeftRadius: 12,
		borderTopRightRadius: 12,
		marginRight: 12,
		alignItems: "center",
		justifyContent: "center"
	},
	tabActive: {
		backgroundColor: COLORS.primary.darkBlue
	},
	tabText: {
		textAlign: "center",
		color: COLORS.primary.darkBlue,
		fontWeight: "400",
		fontSize: 13
	},
	tabTextActive: {
		color: COLORS.primary.white,
		fontWeight: "800"
	},
	button: {
		flexDirection: "row",
		backgroundColor: COLORS.primary.lightBlue,
		maxWidth: 200,
		justifyContent: "center",
		alignItems: "center",
		paddingHorizontal: 20,
		paddingVertical: 6.5,
		marginBottom: 12,
		borderRadius: 20
	},
	flyerContainer: {
		backgroundColor: COLORS.primary.lightestBlue,
		borderWidth: 1,
		borderColor: "rgba(29, 161, 242, 0.4)",
		borderRadius: 5,
		padding: 4,
		paddingHorizontal: 12,
		marginRight: 12
	},
	line: {
		height: 256,
		width: 1,
		backgroundColor: COLORS.primary.neutral_400
	},
	donationRaised: {
		maxWidth: 225,
		width: "100%",
		height: 103,
		bgcolor: COLORS.primary.neutral,
		borderRadius: 12,
		boxShadow: "5px 5px 15px #80808015",
		paddingHorizontal: 20,
		paddingVertical: 15,
		marginTop: "10%",
		marginLeft: "10%"
	}
});
