import {
	getOrgPromise,
	updateOrganization
} from "services/organizations-service";
import { firestore, functions, auth } from "utils/firebase";
import firebase from "firebase/compat/app";

export const getBusinessRayzeAccount = (selectedOrg, setRayzeAccountBalance) => {
	return firestore
		.collection("accounts")
		.where("customerId", "==", selectedOrg?.id)
		.onSnapshot(
			(querySnapshot) => {
				let account;
				if (querySnapshot.empty && selectedOrg?.stripeCustomerId) {
					account = {
						customerId: selectedOrg?.id,
						balance: 0,
						fees: 0,
						id: selectedOrg?.stripeCustomerId
					}
					return firestore
						.collection("accounts")
						.doc(selectedOrg?.stripeCustomerId)
						.set(account);
				};
				account = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
				account.name = selectedOrg?.name;
				setRayzeAccountBalance(account);
			},
			(e) => {
				console.log("getBusinessRayzeAccount error", e);
			}
		);
};

export const getBusinessRayzeAccountTransactions = (rayzeAccount, setRayzeAccountBalance) => {
	return firestore
		.collection("accounts").doc(rayzeAccount?.id)
		.collection("transactions")
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				const transactions = [];
				querySnapshot.forEach((doc) => {
					transactions.push({
						...doc.data(),
						id: doc.id
					});
				});
				setRayzeAccountBalance(transactions);

			},
			(e) => {
				console.log("getBusinessRayzeAccount error", e);
			}
		);
};

export const giftFundsToEmployeeSuccess = (rayzeAccount, amount, employees) => {
	const stringOfEmployeeNames = employees.map(employee => (employee.firstName + " " + employee.lastName)).join(", ");
	const transaction = {
		amount: amount,
		balance: rayzeAccount.balance - amount,
		customerId: rayzeAccount.id,
		date: firebase.firestore.Timestamp.now(),
		description: `Withdrawal to ${employees?.length} employees - ${stringOfEmployeeNames}`,
		fee: 0,
		status: "succeeded",
		to: {
			rayzeIds: employees.map(employee => employee.id),
			stripeIds: employees.map(employee => employee.stripeCustomerId),
			names: employees.map(employee => (employee.firstName + " " + employee.lastName))
		},
		type: "withdrawal",
		employee: true
	}
	console.log('transaction', transaction)
	const batch = firestore.batch();
	const accountRef = firestore.collection("accounts").doc(rayzeAccount.id);
	const transactionRef = accountRef.collection("transactions").doc();
	batch.update(accountRef, { balance: firebase.firestore.FieldValue.increment(-amount) });
	batch.set(transactionRef, transaction);

	employees.forEach(employee => {
		console.log('employee', employee)
		//need to handle if no stripeCustomerId
		const employeeRef = firestore.collection("accounts").doc(employee.stripeCustomerId);
		const employeeTransactionRef = employeeRef.collection("transactions").doc();
		const employeeTransaction = {
			amount: amount,
			// balance: firebase.firestore.FieldValue.increment(amount),
			customerId: employee.stripeCustomerId,
			date: firebase.firestore.Timestamp.now(),
			description: `Deposit from ${rayzeAccount.name}`,
			fee: 0,
			from: {
				rayzeId: rayzeAccount.customerId,
				stripeId: rayzeAccount.id,
				name: rayzeAccount.name
			},
			status: "succeeded",
			type: "deposit",
			employee: true
		}
		batch.set(employeeRef, { balance: firebase.firestore.FieldValue.increment(amount), customerId: employee.id }, { merge: true });
		batch.set(employeeTransactionRef, employeeTransaction);
	});

	return batch.commit();
}