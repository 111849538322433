import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { functions } from "utils/firebase";

export const DonationCard = ({ item, onPress }) => {
  const { userId, amount, date, recurring = false, fee = 0, anonymous = false, processing = false } = item;

  const [user, setUser] = useState(item?.user);
  const [paymentId, setPaymentId] = useState(item.cotPaymentId);

  useEffect(() => {
    if (!user) {
      // console.log('no user')
      userId && getUser(userId, setUser);
    }
  }, [userId]);

  useEffect(() => {
    if (item.cotPaymentId) {
      return setPaymentId(item.cotPaymentId);
    }
    if (!item.paymentId) {
      console.log("no paymentId");
      return;
    } else {
      console.log("paymentId", item.paymentId);
    }
    functions.httpsCallable("getCotPaymentId")({ paymentId: item.paymentId }).then((res) => {
      setPaymentId(res.data);
      item.cotPaymentId = res.data;
    }).catch((err) => {
      console.log(err)
    });
  }, [item.paymentId]);

  // console.log(item?.user)

  return (
    <>
      {(!!userId && !user) ? null : (
        <TableRow hover onClick={anonymous ? () => { } : onPress} style={{opacity: processing ? 0.5 : 1}}>
          <TableCell width={36}>
            <ProfilePic uri={user?.profilePic} userId={userId} size={36} />
          </TableCell>
          <TableCell style={{ textAlign: "left" }}>
            <PrimaryText fontSize={18} fontWeight={900}>
              {user ? getUsersName(user) : (anonymous ? "Anonymous" : item?.name)}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={14} fontWeight={400}>
              {paymentId}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {anonymous ? "" : (item?.email || user?.email)}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {recurring ? "recurring" : "one-time"}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {!processing ? dollarFormatter.format(amount - fee) : "Processing"}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {date.toDate().toDateString()}
            </PrimaryText>
          </TableCell>

        </TableRow>
      )}
    </>
  );
};
